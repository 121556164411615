import { FC } from "react";
import ContactData from "../../../../components/Details/components/ContactData";
import EditableSection from "../../../../components/EditableSection";
import {
  GetEditContactOnFormResponseQueryVariables,
  GetEditContactOnFormResponseDocument,
} from "../../../../graphql/generated/types";
import useContactDetails from "../../../../hooks/useContactDetails";
import { isOrg } from "../../utils/tags";
import OrgData from "../../../../components/Details/components/OrgData";

const ContactPersonalData: FC = () => {
  const { contactData, refetch } = useContactDetails();
  return (
    <EditableSection<{}, GetEditContactOnFormResponseQueryVariables>
      title="Detalhes"
      editQuery={GetEditContactOnFormResponseDocument}
      editPayload={{ contactId: contactData._id! }}
      objectId={contactData._id}
      onSubmit={refetch}
      main
      useV2
    >
      {isOrg(contactData) ? (
        <OrgData org={contactData} />
      ) : (
        <ContactData contact={contactData!} />
      )}
    </EditableSection>
  );
};

export default ContactPersonalData;
