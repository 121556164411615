/** @jsxImportSource @emotion/react */

import { FC, useMemo } from "react";
import useDocumentDetails from "../../../../../hooks/useDocumentDetails";
import DetailsSectionColumns from "../../../../../components/Details/components/Main/Grid/DetailsSectionColumns";
import { DetailsRowProps } from "../../../../../components/Details/components/Main/Grid/DetailsRow";
import EditableSection from "../../../../../components/EditableSection";
import {
  GetEditPaymentDataOnDocumentFormResponseDocument,
  GetEditPaymentDataOnDocumentFormResponseQueryVariables,
} from "../../../../../graphql/generated/types";
import { booleanToYesNo } from "../../../../../utils/stringUtils";
import { DetailsRowValueVariants } from "../../../../../components/Details/components/Main/Grid/DetailsRowValue";
import ContactData from "../../../../../components/Details/components/ContactData";
import { ToContactDetailsLocation } from "../../../../../Locations";
import { IconNames } from "../../../../../components/Icons/styles/iconNames";
import DetailsSection from "../../../../../components/Details/components/Main/Section/DetailsSection";

enum PaymentMethod {
  debit = "débito",
  boleto = "boleto",
}

const PaymentInfo: FC = () => {
  const {
    documentData: { paymentData, _id: documentId, proposalResult },
    refetch,
  } = useDocumentDetails();

  const paymentInfo = useMemo<DetailsRowProps[]>(() => {
    const subventionLabels = [
      proposalResult?.amounts?.federalSubvention && "Subv. Federal",
      proposalResult?.amounts?.stateSubvention && "Subv. Estadual",
    ].filter(Boolean);

    const subventionText =
      subventionLabels.length > 0 ? ` + ${subventionLabels.join(" + ")}` : "";

    return [
      {
        label: "Forma de pagamento",
        value: paymentData?.method && PaymentMethod[paymentData?.method!],
      },
      {
        label: "Número de parcelas",
        value:
          paymentData?.installments &&
          `${paymentData?.installments}${subventionText}`,
      },
      {
        label: "Subvenção estadual?",
        value: booleanToYesNo(paymentData?.stateSubvention),
      },
      {
        label: "Subvenção federal?",
        value: booleanToYesNo(paymentData?.federalSubvention),
      },
    ];
  }, [paymentData, proposalResult]);

  const accountInfo = useMemo<DetailsRowProps[]>(() => {
    return [
      {
        label: "Responsável",
        value: {
          text: paymentData?.responsible?.name || "",
          Component: () => (
            <DetailsSection
              title="Responsável Financeiro"
              main
              footerButtons={[
                {
                  text: "Página do contato",
                  link: ToContactDetailsLocation(paymentData?.responsibleId!),
                  iconName: IconNames.Share,
                },
              ]}
            >
              <ContactData contact={paymentData?.responsible!} />
            </DetailsSection>
          ),
        },
        variant: DetailsRowValueVariants.Modal,
      },
      {
        label: "Banco",
        value: paymentData?.accountData?.bank,
      },
      {
        label: "Agência",
        value: [
          paymentData?.accountData?.agency,
          paymentData?.accountData?.agencyDigit,
        ]
          .filter(Boolean)
          .join(" - "),
      },
      {
        label: "Conta",
        value: [
          paymentData?.accountData?.account,
          paymentData?.accountData?.accountDigit,
        ]
          .filter(Boolean)
          .join(" - "),
      },
    ];
  }, [paymentData]);

  return (
    <EditableSection<{}, GetEditPaymentDataOnDocumentFormResponseQueryVariables>
      title="Dados de pagamento"
      noTitle
      main
      editQuery={GetEditPaymentDataOnDocumentFormResponseDocument}
      objectId={documentId}
      editFlag="admin-only"
      onSubmit={() => {
        refetch();
      }}
    >
      <DetailsSectionColumns columns={[paymentInfo, accountInfo]} />
    </EditableSection>
  );
};

export default PaymentInfo;
